//
// Landing elements
//

// Variables
$landing-dark-color: #13263C;
$landing-header-height: 100px;
$landing-header-height-tablet-and-mobile: 70px;
$landing-header-sticky-height: 70px;
$landing-header-sticky-height-tablet-and-mobile: 70px;

// Utility classes
.landing-dark-bg {
    background-color: $landing-dark-color;
}

.landing-dark-color {
    color: $landing-dark-color;
}

.landing-dark-border {
    border: 1px dashed #2C3F5B;
}

.landing-dark-separator {
    border-top: 1px dashed #2C3F5B;
}

.landing-curve {
    position: relative;

    svg {
        position: relative;
        top: 0;
        display: block;
    }
}

// Landing header
.landing-header {
    display: flex;
    align-items: center;
    height: $landing-header-height;

    // Logos
    .logo-default {
        display: block;
    }

    .logo-sticky {
        display: none;
    }

    // Menu
    .menu {

        // Menu link
        .menu-link {
            &.active {
                color: $white;
            }
        }

        // Header menu sticky mode
        [data-kt-sticky-landing-header="on"] & {

            // Menu link
            .menu-link {
                &.active {
                    color: $primary;
                    background-color: rgba(#EFF2F5, 0.4);
                }
            }
        }
    }

    // Sticky header modes
    [data-kt-sticky-landing-header="on"] & {
        padding: 0;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 100;
        background-color: $white;
        box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
        height: $landing-header-sticky-height;

        // Logos
        .logo-sticky {
            display: block;
        }

        .logo-default {
            display: none;
        }
    }
}

// Fix body padding top when Landing sticky header is on
body[data-kt-sticky-landing-header="on"] {
    padding-top: $landing-header-height;
}

.zoom-img:hover {
    transform: scale(2);
    backdrop-filter: blur(1px);
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {

    // Landing header
    .landing-header {
        height: $landing-header-height-tablet-and-mobile;

        // Menu wrapper
        .landing-menu-wrapper {
            position: relative;
            overflow: auto;
        }

        // Sticky header modes
        [data-kt-sticky-landing-header="on"] & {
            height: $landing-header-sticky-height-tablet-and-mobile;
        }

        // Menu
        .menu {

            // Menu link
            .menu-link {
                &.active {
                    color: $primary;
                    background-color: rgba(#EFF2F5, 0.4);
                }
            }
        }
    }

    // Fix body padding top when Landing sticky header is on
    body[data-kt-sticky-landing-header="on"] {
        padding-top: $landing-header-height-tablet-and-mobile;
    }
}


.landing-index {
    $color-primary: #04A89E;
    $color-secondary: #000050;
    $color-gray: #898989;

    h1 {
        display: block;
        font-size: 6em !important;
        margin-block-start: 0.67em;
        margin-block-end: 0.67em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;

        @include media-breakpoint-down(lg) {
            font-size: 6em !important;
        }

        @include media-breakpoint-down(md) {
            font-size: 5em !important;
        }

        @include media-breakpoint-down(sm) {
            font-size: 4em !important;
        }
    }


    .sub-h1 {
        font-size: 0.75em;
    }

    h2 {
        font-size: 3.5em;
        margin-bottom: 0.5rem;
        font-family: inherit;
        line-height: 1.2;
        color: inherit;
    }

    .btn {
        border-radius: 3rem;
        padding: 0.5rem 1.25rem 0.5rem 1.25rem !important;
    }

    .btn-primary-l {
        color: #fff;
        background-color: $color-primary;
        border-color: $color-primary;
        font-weight: bold;
        width: fit-content;
    }

    .btn-primary-l:hover {
        color: #fff !important;
        background-color: darken($color-primary, 10%) !important;
        border-color: darken($color-primary, 10%) !important;
        width: fit-content;
    }

    .btn-primary-l:active {
        color: #fff !important;
        background-color: darken($color-primary, 20%) !important;
        border-color: darken($color-primary, 20%) !important;
        width: fit-content;
    }

    .text-primary {
        color: $color-primary !important;
    }

    .text-secondary {
        color: $color-secondary !important;
    }

    .text-gray {
        color: $color-gray !important;
    }

    .circle-primary {
        border-radius: 100%;
        padding: 1.25rem;
        width: 40px;
        height: 40px;
        background-color: #04A89E !important;
        font-size: 2rem;
        font-weight: bolder;
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .slider-absolute {
        border: solid 1px #04A89E;
        border-radius: 200px 0px 0px 200px;
        padding-right: 15rem !important;
        background: white;
        border-right: none;
    }

    @media (max-width: 768px) {
        .slider-absolute {
            padding-right: 10rem !important;
        }
    }

    @media (max-width: 576px) {
        .slider-absolute {
            padding-right: 5rem !important;
        }
    }

    .laptop {
        width: 170px;
        height: 170px;
    }

    .service-img-center {
        width: 580px !important;

        @include media-breakpoint-down(xl) {
            width: 430px !important;
        }

        @include media-breakpoint-down(lg) {
            width: 350px !important;
        }

        @include media-breakpoint-down(md) {
            width: 300px !important;
        }
    }

    .bg-round-img {
        background: #04A89E;
        border-radius: 100%;
    }
}

.overlay {
    height: 0%;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 57px;
    left: 0;
    overflow-y: hidden;
    transition: 0.5s;
}

.overlay-content {
    position: relative;
    top: 10%;
    width: 100%;
    text-align: center;
}

.overlay li {
    padding: 8px;
    text-decoration: none;
    font-size: 24px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

.overlay li:hover,
.overlay li:focus {
    color: #f1f1f1;
}

.overlay::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 1, 48, 0.9);
    z-index: 1;
}

.overlay>* {
    position: relative;
    z-index: 2;
}

@media screen and (max-height: 450px) {
    .overlay {
        overflow-y: auto;
    }

    .overlay li {
        font-size: 20px
    }
}

@media (max-width: 576px) {
    .fs-footer-mobile {
        font-size: 9px !important;
    }
}

.whatsapp-icon {
    width: 90px !important;
    height: 90px !important;

    @include media-breakpoint-down(lg) {
        width: 84px !important;
        height: 84px !important;
    }

    @include media-breakpoint-down(md) {
        width: 78px !important;
        height: 78px !important;
    }

    @include media-breakpoint-down(sm) {
        width: 70px !important;
        height: 70px !important;
    }
}

#newsUpdateModal>div, #pwaModal>div {
    background: transparent;
    display: flex;
    align-items: center;
}