//
// Tools
//

// Base
.tools {
    max-width: 600px;
    min-width: 350px;
    width: 60%;
}

.tools-title-radius {
    border-radius: 0px 40px 40px 0px;
}

.tools-header {
    border-radius: 0px 40px 0px 0px;
}

.tools-title {
    font-weight: 700;
    font-size: clamp(10px, 7vw, 32px);
    color: #FFFFFF;
}

.tools-sub-title {
    font-weight: 600;
    font-size: clamp(10px, 4vw, 21px);
    color: #FFFFFF;
}

.tools-content-title {
    font-weight: 700;
    font-size: clamp(10px, 6vw, 28px);
    color: #010D26;
    align-items: center;
    text-align: center;
}

.tools-content-text {
    font-weight: 500;
    font-size: clamp(10px, 5vw, 20px);
    color: #9192A0;
    align-items: center;
    text-align: center;
    margin: 3rem;
}

.tools-content-button {
    background: #4CF3E9;
    border-radius: 11px;

    font-weight: 700;
    font-size: clamp(10px, 5vw, 22px);
    color: #010D26;
    padding: 0.5rem 1rem;
}