//
// Dashboard
//

// Base
.dashboard {
    display: flex;
    flex-direction: column;
    padding: calc(8px + 1.5625vw);
    gap: 15px;
    background-color: white;
    border-radius: 31px;
}

.dashboard-title {
    color: white;
    font-weight: 800;
    font-size: clamp(10px, 5vw, 25px);
    text-align: center;
    padding: 0.5rem 2rem;
    width: fit-content;

    background: #010D26;
    border-radius: 48px;
}

.dashboard-sub-title {
    color: #8A8A8A;
    font-weight: 500;
    font-size: clamp(10px, 5vw, 23px);
    margin-bottom: 2rem;
}

.dashboard-content-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
    gap: 15px;
}

.dashboard-2fr {
    grid-column: span 2;
}

.dashboard-card {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    font-weight: 600;
    font-size: clamp(10px, 4vw, 16px);
    color: #4CF3E9;

    border-radius: 16px;

    padding: 0.5rem 1rem;
    max-height: 200px;
}

.dashboard-card img {
    max-width: 80px;
}

.dashboard button {
    font-weight: 800;
    font-size: clamp(10px, 5vw, 25px);
    color: white;
    width: fit-content;

    border-color: transparent;
    padding: 0.5rem 2rem;
    background: #0072C1;
    border-radius: 48px;
}