//
// Retirar
//

// Base
.retirar {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: white;
    border-radius: 31px;
    padding: calc(8px + 1.5625vw);
}

.retirar-title {
    color: #010D26;
    font-weight: 700;
    font-size: clamp(10px, 5vw, 28px);
    white-space: nowrap;
}

.retirar-sub-title {
    color: #8A8A8A;
    font-weight: 700;
    font-size: clamp(10px, 5vw, 22px);
}

.retirar-select {
    border: 1px solid #010D26;
    border-radius: 13px;
}

.retirar-text-select {
    color: #33B9B1;
    font-weight: 700;
    font-size: clamp(10px, 4vw, 19px);
}

.retirar-text-select sup {
    color: #010D26;
}

.retirar-text {
    color: #A7A7A7;
    font-weight: 600;
    font-size: clamp(10px, 4vw, 14px);
}

.retirar-button {
    background: #4CF3E9;
    border-radius: 11px;
    border: none;
    color: #010D26;
    font-weight: 600;
    font-size: clamp(10px, 4vw, 18px);
}

.retirar-button-cancel {
    background: #EEF2F4;
    border-radius: 11px;
    border: none;
    color: #8A8A8A;
    font-weight: 600;
    font-size: clamp(10px, 4vw, 18px);
}

.retirar-table-head {
    border-radius: 13px;
}

.retirar-table-title th {
    font-weight: 600;
    font-size: clamp(10px, 4vw, 16px);
    color: #010D26;
}