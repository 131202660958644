//
// Config
//

// Base
.config {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: white;
    border-radius: 31px;
    padding: calc(8px + 1.5625vw);
}

.config-title {
    color: white;
    font-weight: 800;
    font-size: clamp(10px, 5vw, 25px);
    text-align: center;
    padding: 0.5rem 2rem;
    width: fit-content;

    background: #010D26;
    border-radius: 48px;
}

.config-sub-title {
    color: #8A8A8A;
    font-weight: 500;
    font-size: clamp(10px, 5vw, 23px);
    margin-bottom: 2rem;
}

.config-form-content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(max(40%, 15rem), 1fr));
    gap: 1rem 2rem;
    grid-auto-flow: row;
}

.config-form-content sup {
    color: #33B9B1;
}

.config-form-item,
.config-form-item-readonly {
    color: #010D26;
    font-weight: 500;
    font-size: clamp(10px, 4vw, 16px);
}

.config-form-item-readonly input {
    background: #EEF2F4;
    border: none;
    border-radius: 11px;
    padding: 1rem;
    width: 80%;
    min-width: 180px;
}

.config-form-item input {
    border: 1px solid #C4D3DA;
    border-radius: 11px;
    padding: 1rem;
    width: 80%;
    min-width: 180px;
}

.react-tel-input .form-control {
    border: 1px solid #C4D3DA !important;
    border-radius: 11px !important;
    padding: 1rem !important;
    height: auto !important;
    width: 80% !important;
    min-width: 180px !important;
}


.config-card img {
    max-width: 80px;
}

//Responsive

@media (max-width: 425px) {

    .config-form-item input,
    .config-form-item-readonly input,
    .react-tel-input .form-control {
        width: 100%;
    }
}