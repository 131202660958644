html {
    font-family: sans-serif;
    text-size-adjust: 100%;
    scroll-behavior: smooth;
}

html,
body {
    height: 100%;
    margin: 0px;
    padding: 0px;
    font-size: $root-font-size !important;
    font-weight: $font-weight-base;
    font-family: $font-family-sans-serif;

    // Tablet mode
    @include media-breakpoint-down(lg) {
        font-size: $root-font-size-lg !important;
    }

    // Mobile mode
    @include media-breakpoint-down(md) {
        font-size: $root-font-size-md !important;
    }

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    a:hover,
    a:active,
    a:focus {
        text-decoration: none !important;
    }
}

body {
    display: flex;
    flex-direction: column;
    color: $body-color;
}

// Angular integration
router-outlet {
    display: none;
}

canvas {
    user-select: none;
}


.pagination-button {
    background-color: #04A89E;
    color: white;
    border-color: #04A89E;
}

.pagination-button.active {
    background-color: #02907B;
    border-color: #02907B;
}

.page-selector-button {
    background-color: #04A89E;
    color: white;
    border-color: #04A89E;
}

.page-selector-button.active {
    background-color: #02907B;
    border-color: #02907B;
}

li.active.page-item>a {
    background-color: #02907B !important;
    color: white !important;
    border-color: #02907B !important;
}

li.active.page-item>a:hover {
    color: black !important;
}

.rounded-6 {
    border-radius: 2rem !important;
}