//
// Transfer
//

// Base
.transfer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: white;
    border-radius: 31px;
    padding: calc(8px + 1.5625vw);
}

.transfer-title {
    color: #010D26;
    font-weight: 700;
    font-size: clamp(10px, 5vw, 28px);
    white-space: nowrap;
}

.transfer-select {
    border: 1px solid #010D26;
    border-radius: 13px;
}

.transfer-text-select {
    color: #33B9B1;
    font-weight: 700;
    font-size: 19px;
}

.transfer-text-select sup {
    color: #010D26;
}

.transfer-text {
    color: #A7A7A7;
    font-weight: 600;
    font-size: 14px;
}

.transfer-button {
    background: #4CF3E9;
    border-radius: 11px;
    border: none;
    color: #010D26;
    font-weight: 600;
    font-size: 18px;
}

.transfer-button-cancel {
    background: #EEF2F4;
    border-radius: 11px;
    border: none;
    color: #8A8A8A;
    font-weight: 600;
    font-size: 18px;
}