//
// Alerts
//

// Base
.alerts-font-24 {
    font-weight: 500;
    font-size: clamp(10px, 5vw, 24px);
}

.alerts-img {
    max-height: 120px;
    min-height: 60px;
    height: 30%;
}

@media (max-width: 425px) {
    .alerts-img {
        display: none;
    }
}