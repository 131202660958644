//
// Profile
//

// Base
.profile {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: white;
    border-radius: 31px;
    padding: calc(8px + 1.5625vw);
}

.profile-head {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
}

.profile-title {
    color: #010D26;
    font-weight: 800;
    font-size: clamp(10px, 5vw, 25px);
    white-space: nowrap;
}

.profile-sub-title {
    color: #8A8A8A;
    font-weight: 700;
    font-size: clamp(10px, 5vw, 20px);
}

.profile-range {
    background: #0072C1;
    border-radius: 10px;

    font-weight: 800;
    font-size: clamp(10px, 5vw, 25px);
    color: #FFFFFF;
    padding: 1rem;
}

.profile-content-m-r {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}

.profile-layout-m-r {
    display: flex;
    align-items: center;
    padding: 0rem 1rem;
    border-radius: 48px;
    border-color: transparent;
    font-weight: 700;
    font-size: clamp(10px, 5vw, 20px);
    white-space: nowrap;
    min-height: 40px;
}

.profile-membership {
    color: #0072C1;
    background-color: #4CF3E9;
}

.profile-renovation {
    color: #FFFFFF;
    background-color: #BBBBBD;
}

.profile-renovation i {
    color: #FFFFFF;
    width: 20px;
}

.profile-nav a {
    font-weight: 700;
    font-size: clamp(10px, 4vw, 20px);
    color: #A7A7A7;
    white-space: nowrap;
}

.profile-nav .nav-link.active {
    color: #0163A7 !important;
    border: none !important;
}

// Detail profile


.profile-title-button {
    color: white;
    font-weight: 800;
    text-align: center;
    padding: 0.5rem 2rem;
    width: fit-content;

    background: #010D26;
    border-radius: 48px;
    font-size: clamp(10px, 5vw, 25px);
}

.profile-text {
    color: #8A8A8A;
    font-weight: 500;
    font-size: clamp(10px, 4vw, 18px);
}

.profile-form-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 18px 0px;
}

// Profile Logo

.profile-logo {
    border-radius: 30px;
    width: 50px;
    height: 50px;

    color: #FFFFFF;
    font-weight: 700;
    font-size: clamp(10px, 5vw, 24px);
}


@media (max-width: 768px) {

    .profile-logo {
        width: 32px;
        height: 32px;
    }
}
