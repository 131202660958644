//
// Referrals
//

// Base
.referrals {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: 15px;
    background-color: white;
    border-radius: 31px;
}

.referrals-head {
    display: flex;
    justify-content: space-between;
}

.referrals-title {
    color: white;
    font-weight: 800;
    font-size: 25px;
    text-align: center;
    padding: 0.5rem 2rem;
    width: fit-content;

    background: #010D26;
    border-radius: 48px;
}

.referrals-sub-title {
    color: #8A8A8A;
    font-weight: 700;
    font-size: 20px;
}

.referrals-range {
    background: #0072C1;
    border-radius: 10px;

    font-weight: 800;
    font-size: 25px;
    color: #FFFFFF;
    padding: 1rem;
}

.referrals-membership {
    display: flex;
    align-items: center;
    padding: 0rem 1rem;
    border-radius: 48px;
    border-color: transparent;

    font-weight: 700;
    font-size: 20px;
    color: #0072C1;
    background-color: #4CF3E9;
}

.referrals-renovation {
    display: flex;
    align-items: center;
    padding: 0rem 1rem;
    border-radius: 48px;
    border-color: transparent;

    font-weight: 700;
    font-size: 20px;
    color: #FFFFFF;
    background-color: #BBBBBD;
}

.referrals-renovation i {
    color: #FFFFFF;
    width: 20px;
}

.referrals-nav a {
    font-weight: 700;
    font-size: 20px;
    color: #A7A7A7;
}

.referrals-nav .nav-link.active {
    color: #0163A7 !important;
    border: none !important;
}

// Detail referrals


.referrals-title-button {
    color: white;
    font-weight: 800;
    font-size: 25px;
    text-align: center;
    padding: 0.5rem 2rem;
    width: fit-content;

    background: #010D26;
    border-radius: 48px;
}

.referrals-text {
    color: #8A8A8A;
    font-weight: 500;
    font-size: 18px;
}

.referrals-form-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 18px 0px;
}