//
// Form Select
//

.form-select {
	box-shadow: none !important;
	border-radius: 15px !important;

	// Solid style
	&.form-select-solid {
		@include placeholder($input-solid-placeholder-color);
		background-color: $input-solid-bg;
		border-color: $input-solid-bg;
		color: $input-solid-color;
		transition: $transition-input;

		.dropdown.show>&,
		&:active,
		&.active,
		&:focus,
		&.focus {
			background-color: $input-solid-bg-focus;
			border-color: $input-solid-bg-focus !important;
			color: $input-solid-color;
			transition: $transition-input;
		}
	}

	// Transparent style
	&.form-select-transparent {
		@include placeholder($input-placeholder-color);
		background-color: transparent;
		border-color: transparent;
		color: $input-color;

		.dropdown.show>&,
		&:active,
		&.active,
		&:focus,
		&.focus {
			background-color: transparent;
			border-color: transparent !important;
			color: $input-color;
		}
	}

	// Solid border
	&.form-select-border {
		@include placeholder($input-solid-placeholder-color);
		background-color: $input-solid-bg;
		border-color: #181C32 !important;
		color: $input-solid-color;
		transition: $transition-input;

		.dropdown.show>&,
		&:active,
		&.active,
		&:focus,
		&.focus {
			background-color: $input-solid-bg-focus;
			border-color: #181C32 !important;
			color: $input-solid-color;
			transition: $transition-input;
		}
	}
}